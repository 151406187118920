var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("仓库物品管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入物品名称" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.inputVal,
              callback: function ($$v) {
                _vm.inputVal = $$v
              },
              expression: "inputVal",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("搜 索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: { click: _vm.add },
            },
            [_vm._v("新增物品")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list mt-15",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "物品名称", align: "center", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { label: "当前库存", align: "center", prop: "amount" },
          }),
          _c("el-table-column", {
            attrs: { label: "物品单位", align: "center", prop: "unit" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编 辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.row)
                          },
                        },
                      },
                      [_vm._v("删 除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-goods", {
        attrs: { show: _vm.goodsDialog, goodsData: _vm.goodsData },
        on: {
          close: function ($event) {
            _vm.goodsDialog = false
            _vm.goodsData = null
          },
          updateView: _vm.getGoodsList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }