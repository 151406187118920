<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="GoodsTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="物品名称：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入物品名称"></el-input>
      </el-form-item>
      <el-form-item v-if="goodsData === null" label="初始数量">
        <el-input-number v-model="formData.amount" :min="0" label="请填写初始数量" :disabled="goodsData !== null"></el-input-number>
      </el-form-item>
      <el-form-item v-if="goodsData !== null"  label="当前数量">
        <span>{{formData.amount}}</span>
      </el-form-item>
      <el-form-item label="物品单位：">
        <el-input v-model="formData.unit" placeholder="请输入物品单位"></el-input>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { addGoods, editGoods } from '@/api/three-planning'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    goodsData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      GoodsTitle: '',
      formData: {},
      rules: {
        title: [{ required: true, message: '请输入物品名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        title: '',
        amount: 0,
        unit: ''
      }
      if (this.goodsData === null) {
        this.GoodsTitle = '新增物品'
      } else {
        this.GoodsTitle = '编辑物品'
        this.formData = Object.assign({}, this.goodsData)
      }
    },

    // 关闭
    close () {
      this.$emit('close')
    },
    saveRoleForm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.goodsData === null) {
            addGoods({
              ...this.formData
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateView')
              }
            })
          } else {
            editGoods({
              ...this.formData
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
</style>
