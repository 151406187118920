<template>
  <el-row class="table">
    <span class="title">仓库物品管理</span>
    <el-row class="row-bg">
      <el-input class="tb-sm-input" placeholder="请输入物品名称" v-model="inputVal" @keyup.enter.native="search"></el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
      <el-button type="info" size="medium" @click="add" class="tb-button">新增物品</el-button>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list mt-15"
    >
      <el-table-column label="物品名称" align="center" prop="title"></el-table-column>
      <el-table-column label="当前库存" align="center" prop="amount"></el-table-column>
      <el-table-column label="物品单位" align="center" prop="unit"></el-table-column>
      <!-- <el-table-column label="状态" align="center" prop="status"></el-table-column> -->
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编 辑</el-button>
          <el-button type="text" @click="del(scope.row)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!--  -->
    <add-goods :show="goodsDialog" @close="goodsDialog=false;goodsData=null" :goodsData="goodsData" @updateView="getGoodsList"></add-goods>
  </el-row>
</template>

<script>
import { getGoodsList, delGoods } from '@/api/three-planning'
import addGoods from '@/components/system/addGoods'

export default {
  components: { addGoods },
  data () {
    return {
      inputVal: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      goodsDialog: false,
      goodsData: null
    }
  },
  created () {
    this.getGoodsList()
  },
  methods: {
    async getGoodsList () {
      const res = await getGoodsList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        title: this.inputVal ? this.inputVal : ''
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getGoodsList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getGoodsList()
    },
    search () {
      this.pagination.currentPage = 1
      this.getGoodsList()
    },
    reset () {
      this.inputVal = ''
    },
    del (row) {
      this.$confirm('是否删除当前问题', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delGoods({
            id: row.id
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('删除成功')
              this.getGoodsList()
            }
          })
        })
    },
    add () {
      this.goodsDialog = true
    },
    edit (row) {
      this.goodsData = row
      this.goodsDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/table.scss'
</style>
